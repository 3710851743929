<template>
  <h1>Jahresübersicht</h1>
  <ul class="subnav mb-1">
    <router-link :to="{name: 'BigBagPage'}">
      <li class="row">
        <label class="col-4">Alle Big Bags</label>
        <div class="col-3 bold text-right">{{ grandTotal() }} kg</div>
        <div class="col-1"></div>
      </li>
    </router-link>
  </ul>
  <div class="row mb-1">
    <div class="col-5 bold">Chargen Gesamtliste</div>
    <div class="col-3 text-right">
      <api-csv-link method="totalslist" class="text-right"/>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-5 bold">Lieferanten Gesamtliste</div>
    <div class="col-3 text-right">
      <api-csv-link method="supplierstotalslist" class="text-right"/>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-4 bold">Trockner Reinigung</div>
    <div class="col-4 text-right">
      <api-csv-link method="cleansinglist" class="float-right"/>
      <api-pdf-link method="cleansinglist" class="float-right mr-2"/>
    </div>
  </div>
  <div v-if="overview()">
    <section
      v-for="regrater in regraters()"
      :key="regrater.contactId">
      <h2>{{ regrater.name }}</h2>
      <ul class="subnav mb-0">
        <router-link
            v-for="detail in getDetailsByRegrater(regrater.contactId)"
            :key="detail.supplierId"
            :to="{name: 'BigBagPage', params: {supplierId: detail.supplierId, regraterId: regrater.contactId}}">
          <li class="row">
            <label class="col-5">{{ supplierName(detail.supplierId) }}</label>
            <div class="col-2 text-right">{{ parseNumber(detail.totalWeight) }} kg</div>
            <div class="col-1"></div>
          </li>
        </router-link>
      </ul>
      <div class="row mb-4">
        <div class="col-4 bold">Summe</div>
        <div class="col-3 bold text-right">{{ regraterTotal(regrater.contactId) }} kg</div>
      </div>
    </section>
  </div>
  <app-btn-back class="pt-2"/>
</template>

<script>
import ApiCsvLink from "@/components/ApiCsvLink";
import ApiPdfLink from "@/components/ApiPdfLink";
export default {
  name: "OverviewPage",
  components: {ApiCsvLink, ApiPdfLink},
  async created() {
    this.$store.state.vessel = []
    await this.$store.dispatch('getItemList', {entityName: 'report', method: 'overview'})
    await this.$store.dispatch('initItems', {entityName: 'supplier'})
    await this.$store.dispatch('initItems', {entityName: 'regrater'})
  },
  methods: {
    overview() {
      return this.$store.state.report
    },
    supplierName(contactId) {
      const supplier = this.$store.state.supplier.find(supplier => supplier.contactId == contactId)
      if (supplier) return supplier.name
      else return null
    },
    regraters() {
      return this.$store.state.regrater
    },
    regraterTotal(contactId) {
      const details = this.getDetailsByRegrater(contactId)
      let total = 0

      for (let idx in details) {
        total += parseFloat(details[idx]['totalWeight'])
      }
      return this.parseNumber(total)
    },
    getDetailsByRegrater(contactId) {
      return this.$store.state.report.filter(detail => detail.regraterId == contactId)
    },
    grandTotal() {
      const details = this.overview()
      let total = 0

      for (let idx in details) {
        total += parseFloat(details[idx]['totalWeight'])
      }
      return this.parseNumber(total)
    },
    formattedInt(number) {
      return parseInt(number).toLocaleString('de-DE')
    },
    parseNumber(number) {

      if (parseInt(number) != number)
        number = parseFloat(number)
      else
        number = parseInt(number)
      //return number
      return number.toLocaleString('de-DE')
    },
  }
}
</script>

<style scoped>
h2 {
  padding-top: 0.5rem;
}

.subnav li:after {
  margin-left: -16px;
}
</style>